import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import api from "../../services/api";
import BootstrapInput from '../Input/BootstrapInput';
import usePrevious from '../../hooks/usePrevious';


const AsyncSelect = ({ 
    url,
    dictKey,
    onChange, 
    label,
    initialValue,
    statuses,
    defaultValue, 
    multiple, 
    width,
    renderOption,
    limitOptions, 
    renderTags, 
    multiline, 
    disableCloseOnSelect, 
    small, 
    addFirstOption, 
    includeContactsCount,
    selectFirstIfOnlyOne,
    queueIds
     }) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [query, setQuery] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);

    const prevFilter = usePrevious({ pageNumber, query });
    const [internalValue, setInternalValue] = React.useState(initialValue || multiple ? [] : null);
    const loading = open && options && options.length === 0;
    let debounce = null;

    const labelOffset = -6;
    const [focused, setFocused] = React.useState(false);
    const [selectedQueues, setSelectedQueues] = useState([]);

    React.useEffect(() => {
        let active = true;
        const delayDebounceFn = setTimeout(() => {
            if (url) {
          
                const fetchOptions = async () => {
                    let params = { pageNumber, queueId: JSON.stringify(selectedQueues) };
                    if (query) {
                        params.searchParam = query;
                    }
                    const { data } = await api.get(url, { params });

                   // console.log(data, 'test')
                    if (active) {
                        // Check if initialValue is on the list
                        let localOptions;
                        if (dictKey) {
                            localOptions = data[dictKey];
                            //console.log('localdata',  data)
                        } else {
                            localOptions = data;
                        }

                        if (addFirstOption) {
                            localOptions = [addFirstOption, ...localOptions];
                        }

                        if (prevFilter && prevFilter.pageNumber != pageNumber) {
                            localOptions = [...options, ...localOptions];
                        }
                        setOptions(localOptions);
                    }
                }
                fetchOptions();
            }
            if(statuses) {
                setOptions(statuses); 
            }
        }, 500);

        return () => {
            active = false;
            clearTimeout(delayDebounceFn);
        };
    }, [query, open, pageNumber, selectedQueues]);

    React.useEffect(() => {
        setPageNumber(1);
    }, [query, open]);

    React.useEffect(() => {
        if (initialValue) {
            setInternalValue(initialValue);
            onChange(null, initialValue);
        }
    }, [initialValue]);


    React.useEffect(() => {
       if(renderOption){
      //  console.log('options', options)
       }
    }, [renderOption]);


    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    React.useEffect(() => {
        if (options.length === 1 && selectFirstIfOnlyOne) {
            setInternalValue(options[0]);
            onChange(null, options[0]);
        }
    }, [options]);

    useEffect(() => {
        //console.log(queueIds, 'passed in here AsyncSelect queues')
        if (queueIds) {
            setSelectedQueues(queueIds)
        }
    }, [queueIds])

    const onInputChange = (event, value) => {
        if (debounce) {
            clearTimeout(debounce);
        }
        debounce = setTimeout(() => {
            setQuery(value);
        }, 500);
    }

    const loadMore = () => {
        setPageNumber(pageNumber + 1);
    }

    const handleFilterOptions = (options, { inputValue }) => {
        return options.filter(option => {
            if (!inputValue) return true;
            if (option && option.name && inputValue && option.name.toLowerCase().includes(inputValue.toLowerCase())) {
                return true;
            }
            if (option && option.number && inputValue && option.number.toLowerCase().includes(inputValue.toLowerCase())) {
                return true;
            }
            return false;
        });
    }

    return (
        <React.Fragment>
            
            <Autocomplete
                multiple={multiple}
                id={`autocomplete-${dictKey}`}
                style={{ width: width || 300}}
                open={open}
                //value={selectedValue}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onFocus={() => {
                    setFocused(true);
                }}
                onBlur={() => {
                    setFocused(false);
                }}
                disableCloseOnSelect={disableCloseOnSelect ? disableCloseOnSelect : undefined}
                value={internalValue}
                defaultValue={defaultValue}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name && includeContactsCount ? `${option.name} ${option.contacts_count ? `(${option.contacts_count})`: ''}` : option.name || ''}
                options={options}
                loading={loading}
                input={small ? <BootstrapInput /> : undefined}
                onChange={(event, value) => {setInternalValue(value); onChange(event, value) }}
                onInputChange={onInputChange}
                filterOptions={handleFilterOptions}
               // renderOption={renderOption ? renderOption : undefined}
                renderTags={renderTags ? renderTags : undefined}
                ListboxProps={{
                    onScroll: (event) => {
                        if (loading) return;
                        const { scrollTop, scrollHeight, clientHeight } = event.target;
                        if (scrollTop + clientHeight === scrollHeight) {
                            url !== '/queue' && loadMore();
                        }
                    }
                }}
                renderInput={(params) => (

                    <>
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputLabelProps={{
                            style: {
                                ...(!focused && { top: `${labelOffset}px` }),
                            },
                        }}
                        InputProps={{
                            ...params.InputProps,
                            style: {
                                padding: '2px 10px'
                            },
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}

                    />
                    </>
                )}
            />
        </React.Fragment>
    );
}
export default AsyncSelect;